








import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "Mail",
        },
        content: {
          ignoreOtherFields: true,
          sections: {
            default: {
              fields: {
                mailHost: {
                  attrs: {
                    label: "Mail Host",
                  },
                },
                mailPort: {
                  type: "integer",
                  attrs: {
                    label: "Mail Port",
                  },
                },
                mailFrom: {
                  attrs: {
                    label: "Mail Form",
                  },
                },
                mailUsername: {
                  attrs: {
                    label: "Mail Username",
                  },
                },
                mailPassword: {
                  type: "password",
                  attrs: {
                    label: "Mail Password",
                  },
                },
              },
            },
          },
          actionButtons: {
            save: {
              content: {
                text: "Save",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const data = form.getData();
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call("setting", "update", undefined, {
                    data,
                  });
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "Mail saved successfully";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  async created() {
    const data = await coreApiClient.call("setting", "get");
    this.dataContainer.value = data;
  },
});
